import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HeightIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path fill="#303030" fillRule="nonzero" d="M6.025 10.642c-.233-.234-.617-.234-.85 0-.233.233-.233.616 0 .85l1.817 1.816c.233.234.616.234.85 0l1.816-1.816c.117-.117.167-.267.167-.417 0-.15-.067-.3-.167-.417-.233-.233-.616-.233-.85 0l-.8.8V4.042l.8.8c.234.233.617.233.85 0 .234-.234.234-.617 0-.85L7.842 2.175c-.234-.233-.617-.233-.85 0l-1.8 1.817c-.117.116-.167.266-.167.416 0 .15.05.3.167.417.233.233.616.233.85 0l.8-.8v7.4l-.817-.783zM10.283.083h-5.9c-.133 0-.25.117-.25.25v.684c0 .133.117.25.25.25h5.884c.133 0 .25-.117.25-.25V.333c.016-.133-.1-.25-.234-.25zM10.283 14.717h-5.9c-.133 0-.25.116-.25.25v.7c0 .133.117.25.25.25h5.884c.133 0 .25-.117.25-.25v-.684c.016-.15-.1-.266-.234-.266z" />
      </g>
    </SvgIcon>
  );
}
