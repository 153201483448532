import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WeightIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path fill="#303030" fillRule="nonzero" d="M12.925 10.972l-1.234-3.934c-.173-.55-.61-.943-1.164-1.06v-1.06C10.527 3.861 9.66 3 8.594 3H7.406c-.524 0-.997.194-1.367.562-.37.367-.566.836-.566 1.356v1.06c-.553.117-.99.51-1.164 1.06l-1.234 3.934c-.153.487-.07.98.235 1.391.304.411.754.637 1.268.637h6.844c.513 0 .964-.226 1.268-.637.305-.41.388-.905.235-1.391zM7.051 4.566c.103-.102.21-.146.355-.146h1.188c.277 0 .502.224.502.498v.91l-.51-.05-.035-.001H7.45c-.012 0-.023 0-.034.002l-.51.049v-.91c0-.144.043-.25.146-.352z" />
      </g>
    </SvgIcon>

  );
}
